import React, { useState, useEffect } from 'react';
import { parse, isValid, format, min, max } from 'date-fns';
import httpcontactus from "../Js/http-EB5_contactus";
import OTPInput, { ResendOTP } from "otp-input-react";
import Spinner from 'react-bootstrap/Spinner';
// import axios from 'axios';

const Main = () => {

  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState({});
  const [verifyMsg, setVerifyMsg] = useState([])
  const [careerContactId, setCareerContactId] = useState([])
  const [isStaticEvent, setIsStaticEvent] = useState(false);
  const [loading, setLoading] = useState(false)
  const [timer, setTimer] = useState(0);

  

  useEffect(() => {
    getEventList()
     }, []);


     
  

   
  const [inputerr, setInputerr] = useState({});
  const [pop, setPop] = useState(false);
  const [error, setError] = useState();
  const [otpSent, setOtpSent] = useState(false); // To track if OTP has been sent
  const [otp, setOtp] = useState(''); // To store the OTP entered by the user
  const [otpVerified, setOtpVerified] = useState(false); // To track if OTP is verified
  const [showForm, setShowForm] = useState(false); // To control form visibility
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [selectedEventTitle, setSelectedEventTitle] = useState('');
  const [selectedVenueDetails, setSelectedVenueDetails] = useState('');
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    // country: "",
    comment: "",
    appCode: "EB5",
    // index_code: ""
  });

  const eventIndexCodes = {
    'Shoora EB5 Investors Live Event - La Casa Brewery + Kitchen - Bengaluru': 91,
    'Shoora EB5 Investors Live Event - The HSR Club - Bengaluru': 90,
    'Shoora EB5 Investors Live Event - TAJ KRISHNA - Hyderabad': 89,
    'EB5 Investment Green Cards Event - JW Marriott Marquis Hotel Dubai': 92,
    'Shoora EB5 Investors Live Event - Hilton Alpharetta Atlanta - Alpharetta, GA': 93,
    'Shoora EB5 Investors Live Event - Diwali Celebrations - Cumming, GA': 94 
  };




  const convertZuluToEST = (zuluTime) => {
    if (!zuluTime) {
      console.warn("Invalid or missing Zulu time:", zuluTime);
      return "";  // Return an empty string if zuluTime is invalid
    }
  
    console.log("Received Zulu time:", zuluTime);  // Log the input for debugging
  
    // Parse the Zulu time format (either HH.MMZ or HH:MMZ)
    const [hours, minutes] = zuluTime.includes('.') ? zuluTime.split('.') : zuluTime.split(':');
  
    // Create a UTC-based date object with parsed hours and minutes
    const utcDate = new Date(Date.UTC(1970, 0, 1, parseInt(hours), parseInt(minutes)));
  
    // Adjust to EST (UTC -5 hours)
    const estDate = new Date(utcDate.getTime() - (5 * 60 * 60 * 1000));
  
    // Format to HH:MM AM/PM in EST
    const estHours = estDate.getUTCHours() % 12 || 12; // Convert 0 to 12 for AM/PM
    const estMinutes = String(estDate.getUTCMinutes()).padStart(2, '0');
    const period = estDate.getUTCHours() < 12 ? 'AM' : 'PM';
  
    return `${estHours}:${estMinutes} ${period} EST`;
  };





  useEffect(() => {
    // Automatically select the first venue if only one venue exists or if multiple venues exist
    if (events) {
      const venues = events[selectedEventTitle]?.venues;
      if (venues && venues.length > 0) {
        if (venues.length === 1) {
          // If there's only one venue, automatically select it
          setSelectedVenueDetails(`${venues[0].cityName} - ${venues[0].eventDate} @ ${convertZuluToEST(venues[0].startTime)} - ${venues[0].venue}`);
          setSelectedVenue(venues[0].locationId);
        } else {
          // If multiple venues, set to null to require user selection
          setSelectedVenue(null);
          setSelectedVenueDetails(''); // Clear the selected venue details
        }
      }
    }
  }, [events, selectedEventTitle]);
  
 

  useEffect(() => {
    // Check if fields are filled
    const isFormValid = data.firstName && data.lastName && data.email;
    const isDynamicEvent = !eventIndexCodes;
  
    // Enable button based on static/dynamic requirements
    setIsButtonEnabled(isFormValid && (isDynamicEvent ? selectedVenue : true));
  }, [data, selectedVenue]);

 
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
  };

  const validateComment = (comment) => {
    return comment.trim().length > 0; // Returns true if comment is not empty or just spaces
  };
  

  // const handleInput = (event) => {
  //   const { name, value, type, checked } = event.target;
  //   let fieldValue = type === "checkbox" ? checked : value;

  //   // If the field is phoneNumber, remove non-numeric characters and limit length to 10
  //   if (name === "phoneNumber") {
  //     // Remove any non-numeric characters
  //     fieldValue = fieldValue.replace(/\D/g, "");

  //     // Limit length to 10 characters
  //     fieldValue = fieldValue.slice(0, 10);

  //     // Check if the phone number has exactly 10 digits
  //     const isValidPhoneNumber = fieldValue.length === 10;

  //     // Set the error message if the phone number is invalid
  //     setInputerr((prevErrors) => ({
  //       ...prevErrors,
  //       phoneNumber: isValidPhoneNumber
  //         ? ""
  //         : "Phone number must be of 10 digits!",
  //     }));
  //   }

  //   setData((prevData) => ({
  //     ...prevData,
  //     [name]: fieldValue,
  //   }));
  // };


  const validateOTP = () => {
    let err = {};
    if (data.firstName === "") {
      err.firstName = "*First name is required!";
    }
    if (data.lastName === "") {
      err.lastName = "*Last name is required!";
    }
    if (data.email === "") {
      err.email = "*Email ID is required!";
    } else if (!validateEmail(data.email)) {
      err.email = "*Invalid email format!";
    }
  
    setInputerr({ ...err });
    console.log(err);
    
    return Object.keys(err).length === 0; // Return true if there are no validation errors
  };


  const validateForm = () => {
    let err = {};
    if (data.firstName === "") {
      err.firstName = "*First name is required!";
    }
    if (data.lastName === "") {
      err.lastName = "*Last name is required!";
    }
    if (data.email === "") {
      err.email = "*Email ID is required!";
    } else if (!validateEmail(data.email)) {
      err.email = "*Invalid email format!";
    }
    if (data.comment === "") {
      err.comment = "*Please mention time period!";
    } else if (!validateComment(data.comment)) {
      err.comment = "*Please mention time period!";
    }
    if (data.phoneNumber === "") {
      err.phoneNumber = "*Phone Number is required!";
    } else if (!validatePhoneNumber(data.phoneNumber)) {
      err.phoneNumber = "*Invalid phone number!";
    }
    // if (data.countryId === "") {
    //   err.country = "*Country is required!";
    // }
    setInputerr({ ...err });
    console.log(err);
    
    return Object.keys(err).length === 0; // Return true if there are no validation errors
  };

  const parseDate = (dateString) => {
    // Check if dateString is a valid string
    if (typeof dateString === "string") {
      const [month, day, year] = dateString.split("-");
      return new Date(`${year}-${month}-${day}`);
    }
    // If it's already a Date object, return it directly
    else if (dateString instanceof Date) {
      return dateString;
    }
    // Return an invalid date if it's not a valid string or Date object
    return new Date(NaN);
  };
  

  const handleVenueSelect = (venue) => {
    setSelectedVenueDetails(`${venue.cityName} - ${venue.eventDate} @ ${convertZuluToEST(venue.startTime)} - ${venue.venue}`);
    setSelectedVenue(venue.locationId);
  };
  
  useEffect(() => {
    const isStaticEvent = eventIndexCodes[selectedEventTitle] !== undefined;
    setShowForm(isStaticEvent); // Show form automatically for static events
  }, [selectedEventTitle]);

  const handleNext = () => {
    if (selectedVenue) {
      setShowForm(true);
    } else {
      setError("Please select a venue first.");
    }
  };
  
  const handleShow = (title) => {
    setSelectedEventTitle(title);
    setShowModal(true);
    setIsStaticEvent(!!eventIndexCodes[title])
    setShowForm(false); // Reset form visibility on modal open
  };
  
  const handleClose = () => {
    setShowModal(false);
    // setSelectedVenue(null); 
    setShowForm(false);
    setError("")
    setVerifyMsg("")
    setOtpSent(false);
    setTimer(0)
    setData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      // country: "",
      comment: "",
      appCode: "EB5",
      // index_code: ""
    });
  };


  const handleInput = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };
  

  
  // const handleonClick = () => {
  //   // Handle form submission
  //   console.log("Form submitted", data, selectedVenue);
  // };

  // const eventDates = Array.isArray(events?.eventDates)
  // ? events.eventDates.map(date =>
  //     date ? parse(String(date), 'MM-dd-yyyy', new Date()) : null
  //   ).filter(isValid)
  // : [];

  useEffect(() => {
    let countdown;
    if (otpSent && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setOtpSent(false); // Allow resend after timer reaches 0
    }
    return () => clearInterval(countdown); // Cleanup interval on unmount
  }, [otpSent, timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };



  const handleSendOTP = () => {
    console.log('aaaaaaaaaaaa');
    
    const isvalid = validateOTP(); // Ensure form is valid before sending OTP

    if (isvalid) {

      setLoading(true)

      const otpData = { appCode:data.appCode, email: data.email, firstName: data.firstName, lastName:data.lastName }; // Assuming OTP is triggered by email
      httpcontactus
        .post("/allContactUS/sendMailotp", otpData)
        .then((response) => {
          // Handle OTP sent success
          setOtpSent(true); // Show the OTP input field
          console.log("OTP sent successfully");
          setCareerContactId(response.data.careerContactId)
          setLoading(false)
      setOtpSent(true);
      setTimer(300)
        })
        .catch((err) => {
          // Handle error while sending OTP
          console.error("Error sending OTP:", err);
          setError("There was an issue sending the OTP.");
          setLoading(false)
        });
    } else {
      alert("Please fill in the required fields.");
    }
  };
  
  const handleVerifyOTP = () => {
    const enteredOtp = otp; // The OTP entered by the user
    const email = data.email; // Email used to send the OTP
  
    httpcontactus
      .put(`/allContactUS/verifyotp?email=${email}&enteredOtp=${enteredOtp}`)
      .then((response) => {
        if (response.data.includes("verified")) {
          console.log("OTP verified");
          setVerifyMsg(response.data)
          setError(null)
          setOtpVerified(true); // Allow form submission after OTP verification
          setOtpSent(false)
          setOtp("")
        }  else {
          setError("OTP verification failed. Please try again.");
          setOtp("");
          // Clear the error after 5 seconds
          setTimeout(() => setError(null), 5000);
        }
      })
      .catch((err) => {
        console.error("Error verifying OTP:", err);
        setError("There was an issue verifying the OTP.");
      });
  };
  
  

  const handleonClick = () => {
    if (!otpVerified) {
      alert("Please verify OTP first.");
      return;
    }
  
    const isStaticEvent = eventIndexCodes[selectedEventTitle] !== undefined;
    const formData = {
      ...data,
      careerContactId,
      locationId: isStaticEvent ? undefined : selectedVenue,
      index_code: isStaticEvent ? eventIndexCodes[selectedEventTitle] : undefined,
    };
  

    const isvalid = validateForm(); // Ensure form is valid before sending OTP
    
    if (isvalid) {

      httpcontactus
      .post("allContactUS/saveContactData", formData)
      .then((response) => {
        console.log('Form submitted successfully:', response.data);
  
        setData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          country: "",
          comment: "",
          appCode: "EB5",
          index_code: "",
        });
  
        if (response.data === "Your query is successfully submitted will get back to you soon") {
          setShowModal(false);
          setPop(true);
          setCareerContactId("")
          setVerifyMsg("")
          setOtpVerified(false)
          // setSelectedVenue(null);
          setShowForm(false);
          setError("")
          setOtpSent(false);
          setTimer(0)
          setData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            // country: "",
            comment: "",
            appCode: "EB5",
            // index_code: ""
          });

          setTimeout(() => setPop(false), 2800);
        }
        else if   (response.data === "Duplicate Record for same event Please provide Valid info")
        {
          setError("Duplicate Record for same event Please provide Valid info");
          setVerifyMsg("")
          setOtpSent(false)
          setOtpVerified(false)
        }
         else {
          setError("There was an error with the submission. Please report an issue: yugandhar@shooraeb5.com");
        }
      })
      .catch((err) => {
        console.error("Error submitting form:", err);
        setError("There was an error with the submission. Please report an issue: yugandhar@shooraeb5.com");
      });

    }else {
      alert("Please fill in all the required fields.");
    }

    
  };
  
 

  const formatEventStartTime = (eventDate, startTime) => {
    console.log('eventDate:', eventDate, 'startTime:', startTime); // Debugging line
  
    if (!startTime) {
      return null; // Return null if startTime is not defined
    }
  
    const [hours, minutesWithTimezone] = startTime.split(':'); // Split the time into hours and minutes
    const minutes = minutesWithTimezone.slice(0, 2); // Extract minutes
    const timezone = minutesWithTimezone.slice(2); // Extract timezone if needed
  
    // Create a new Date object from the event date and the specified time
    return new Date(`${eventDate}T${hours}:${minutes}:00${timezone}`);
  };
  
  
  


  const getEventList = () => {
    const requestBody = {
      cityName: null,
      stateName: null,
      countryName: null,
      eventName: null,
      eventType: null,
      venue: null,
      eventDate: null
    };

    httpcontactus
      .post('/eventDetails/getEventDetailsWithFilters', requestBody)
      .then((response) => {
        if (response.data === 'No records found') {
          setError(response.data);
        } else {
          const groupedEvents = groupEventsByEventName(response.data);
          setEvents(groupedEvents);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Group events by event name and gather event details

//   const groupEventsByEventName = (apiResponse) => {
//     return apiResponse.reduce((acc, event) => {
//         const { eventName, venue, cityName, stateName, startTime, countryName, eventDate, locationId } = event;

//         if (!acc[eventName]) {
//             acc[eventName] = {
//                 eventName,
//                 eventDates: [],
//                 venues: [],
//             };
//         }

//         // Push event dates and location details as strings
//         acc[eventName].eventDates.push(eventDate); // Keep eventDate as is
//         acc[eventName].venues.push({
//             venue,
//             cityName,
//             stateName,
//             startTime,
//             countryName,
//             locationId,
//             eventDate, // Add eventDate here
//         });

//         return acc;
//     }, {});
// };

const groupEventsByEventName = (apiResponse) => {
  return apiResponse.reduce((acc, event) => {
    const { eventName, venue, cityName, stateName, startTime, countryName, eventDate, locationId, status } = event;

    // Skip events with status 'Inactive'
    if (status === 'Inactive') {
      return acc;
    }

    if (!acc[eventName]) {
      acc[eventName] = {
        eventName,
        eventDates: [],
        venues: [],
      };
    }

    // Push event dates and location details as strings
    acc[eventName].eventDates.push(eventDate); // Keep eventDate as is
    acc[eventName].venues.push({
      venue,
      cityName,
      stateName,
      startTime,
      countryName,
      locationId,
      eventDate, // Add eventDate here
    });

    return acc;
  }, {});
};



  return (
    <>

{pop && (
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
          <div className=" d-flex flex-column justify-content-center align-items-center P-Update-Success-2 ">
            <div>
              <img
                src={require("../Images/Tick.png")}
                alt="success"
                className="img-fluid"
              />
            </div>
            <p className="text-center w213 ">
            Thank you for registering the event, Look forward to meet you.
            </p>
          </div>
        </div>
      )}

<div style={{ backgroundColor: '#ff792c', textAlign: 'center', padding: '10px' }}>
        <strong className="white">H-1B / F-1 / E-2 / TN  Visa Holders Can Invest Now & Adjust Immigration Status Immediately</strong>
      </div>


      <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: '#2e2c7d' }}>
      <div className="container-fluid">
        <a className="navbar-brand" href="#home">
        <img
            className="my-2 ms-2"
            // onClick={handleClickHome}
            src={require("../Images/shooraLogo.jpg")}
            alt="logo"
            style={{ height: "60px" }}
          />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              {/* <strong className="nav-link active white" aria-current="page" href="#contact">+1 470-435-1724</strong> */}
              <div className="d-flex">
              <img
                className=""
                src={require("../Images/whatsappLogo.png")}
                alt="logo"
                style={{ height: "45px" }}
              />
              <strong className="white mt-2 ">+1 470-435-1724</strong>
              </div>
             
            </li>
          </ul>
        </div>
      </div>
    </nav>



    <h2 className="shooraOrr text-center mt-5 px-3 border-bottom pb-3">ShooraEB5 is conducting live events, Welcome to attend to know more about. </h2>

   



<div className="" style={{ padding: '20px', backgroundColor: '#fefefe' }}>
      <h2 className="text-center mb-5 mt-3 shooraOrr border-bottom pb-3">Event Details</h2>

      <h3 className=' ps-md-3 mb-3'>Latest Events:</h3>

 

{/* 
      <div className="row mx-md-3">
  <div className="col-lg greyBg rounded-2 py-2 ps-3 me-md-3 shadow-sm" style={{ marginBottom: '45px' }}>
    <h4 className="mb-4 shooraBlue mt-2">Meet@ Dr. Chennakesava Reddy Kummathi - JW Marriott Marquis Hotel - Dubai</h4>
    <p><strong>Date:</strong> <strong>17th to 22nd September 2024, Tuesday to Sunday</strong> </p>
    <p><strong>Venue:</strong> JW Marriott Marquis Hotel</p>
    <p><strong>Timing:</strong> 9:00 AM to 7:00 PM (Local Time)</p>
    <p><strong>Location:</strong></p>
    <p>JW Marriott Marquis Hotel - Dubai</p>
    <p>Business Bay - Dubai - United Arab Emirates</p>
    <p><strong>Contact for appointment:</strong> WhatsApp +1 423 747 4740</p>
    <p><strong>Email:</strong> <a href="mailto:drckreddy@shooraeb5.com">drckreddy@shooraeb5.com</a></p>
    <p><strong>Hotel Phone:</strong> +971 4 414 0000</p>
    
    <div className="d-flex justify-content-between me-5">
     
    </div>
  </div>
</div> */}
   

    
      
   {events && Object.keys(events).length > 0 ? (
  Object.keys(events).map((eventName, index) => {
    const event = events[eventName];

    // Safely handle undefined or missing eventDates and venues
    const eventDates = event?.eventDates || [];
    const venues = event?.venues || [];

    // Get the minimum and maximum event dates (if there are dates)
    const sortedDates = eventDates.sort(); // Lexicographically sort MM-DD-YYYY dates
    const minDate = sortedDates[0] || null;
    const maxDate = sortedDates[sortedDates.length - 1] || null;

    return (
      <div
        key={index}
        className="row mx-md-3 greyBg rounded-2 py-2 ps-3 me-md-3 shadow-sm"
        style={{ marginBottom: "45px" }}
      >
        {minDate && (
          <div className="col-lg-3 col py-4 d-flex dateCol align-items-center m-4 rounded flex-column justify-content-center align-items-center">
            <h1 className="white font-14">{minDate}</h1>
            {maxDate && minDate !== maxDate && (
              <>
                <h1 className="ms-2 white font-14">to</h1>
                <h1 className="white font-14">{maxDate}</h1>
              </>
            )}
          </div>
        )}

        <div className="col-lg-3 col ps-lg-4 my-3">
          <h1 className="mb-4 shooraBlue mt-2 font-14">{event.eventName}</h1>

          {venues.length > 0 ? (
            venues.map((location, locIndex) => (
              <h3 className="font-14" key={locIndex}>
                {location.cityName}, {location.stateName}, {location.countryName}
              </h3>
            ))
          ) : (
            <h3>No venue information available</h3>
          )}
        </div>

        <div className="col-lg-3 col ps-lg-4 my-3">
          <button
            className="header-contact-btn label-btn label-background-color col-white ms-2 h56 mt-lg-0 px-3 py-2"
            onClick={() => handleShow(event.eventName)}
          >
            Register
          </button>
        </div>
      </div>
    );
  })
) : (
  <p className="ps-md-3">No events available.</p>
)}







{/* <h3 className=' ps-md-3 mb-3'>Past Events:</h3> */}



{/* <div className="row mx-md-3">
          <div className="col-lg greyBg rounded-2 py-2 ps-3 me-md-3 shadow-sm" style={{ marginBottom: '45px' }}>
           
            
            <div className="d-lg-flex justify-content-between me-5">
              <div>
              <h4 className="mb-4 shooraBlue mt-2">Shoora EB5 Investors Live Event - Diwali Celebrations - Cumming, GA</h4>
              <p><strong>Date:</strong> <strong>2nd November 2024, Saturday</strong></p>
            <p><strong>Venue:</strong>  Piney Grove Middle School</p>
            <p><strong>Timing:</strong> 5:00 PM to 10:00 PM (Local Time)</p>
            <p><strong>Location:</strong></p>
            <p>8135 Majors Rd, Cumming, Georgia, 30041, USA</p>
            <p><strong>Contact for appointment:</strong> WhatsApp +1 770 366 7122 (Swapna Kaswa)</p>
        
            <p><a href="https://www.google.com/maps/dir//8135+Majors+Rd,+Cumming,+GA+30041,+United+States/@34.144139,-84.2610548,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f59b846ebcb309:0xcffd01085d69a36a!2m2!1d-84.1786524!2d34.1441578!5m1!1e1?entry=ttu&g_ep=EgoyMDI0MTAxMy4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer">View Map</a></p>
              <p>
  <a
    className='shooraBluehover'
    onClick={() => handleShow('Shoora EB5 Investors Live Event - Diwali Celebrations - Cumming, GA')}
    target="_blank"
    rel="noopener noreferrer"
  >
    Click here to register for the event.
  </a>
</p>
              </div>
           
<div  className=" pe-lg-5 me-lg-5 d-flex mt-5 mb-4 mt-lg-3 mb-lg-3 ps-5 ps-lg-0 justify-content-center"
            style={{ height: '650px' }}
            >
<img
           
            src={require("../Images/Nov2Event.jpg")}
            alt="event"
            
          />
</div>
             
              
           
            </div>
    
      
        
          </div>
        </div> */}


      {/* <div className="row mx-md-3">
  <div className="col-lg greyBg rounded-2 py-2 ps-3 me-md-3 shadow-sm" style={{ marginBottom: '45px' }}>
    <h4 className="mb-4 shooraBlue mt-2">Meet@ Dr. Chennakesava Reddy Kummathi - JW Marriott Marquis Hotel - Dubai</h4>
    <p><strong>Date:</strong> <strong>17th to 22nd September 2024, Tuesday to Sunday</strong> </p>
    <p><strong>Venue:</strong> JW Marriott Marquis Hotel</p>
    <p><strong>Timing:</strong> 9:00 AM to 7:00 PM (Local Time)</p>
    <p><strong>Location:</strong></p>
    <p>JW Marriott Marquis Hotel - Dubai</p>
    <p>Business Bay - Dubai - United Arab Emirates</p>
    <p><strong>Contact for appointment:</strong> WhatsApp +1 423 747 4740</p>
    <p><strong>Email:</strong> <a href="mailto:drckreddy@shooraeb5.com">drckreddy@shooraeb5.com</a></p>
    <p><strong>Hotel Phone:</strong> +971 4 414 0000</p>
    
    <div className="d-flex justify-content-between me-5">
     
    </div>
  </div>
</div> */}



     

      {/* <div className="row mx-md-3">
        <div className="col-lg greyBg rounded-2 py-2 ps-3 me-md-3 shadow-sm" style={{ marginBottom: '45px' }}>
        <h4 className="mb-4 shooraBlue mt-2">Shoora EB5 Investors Live Event - TAJ KRISHNA - Hyderabad</h4>
          <p><strong>Date:</strong> <strong>07th August 2024, Wednesday</strong> </p>
          <p><strong>Venue:</strong> Sapphire</p>
          <p><strong>Timing:</strong> 3:00 PM to 9:00 PM (Local Time)</p>
          <p><strong>Location:</strong></p>
          <p>TAJ KRISHNA</p>
          <p>Road No. 1, Banjara Hills</p>
          <p>Hyderabad-500034, Telangana, India</p>

          <div className="d-flex justify-content-between me-5">
            <p><a href="https://maps.app.goo.gl/xRmZ3QT2tJqp8ZcC9" target="_blank" rel="noopener noreferrer">View Map</a></p>
            <p><a className='shooraBluehover' onClick={() => handleShow('Shoora EB5 Investors Live Event - TAJ KRISHNA - Hyderabad')} target="_blank" rel="noopener noreferrer">Click here to register for the event.</a></p>
          </div>
        </div>

        <div className="col-lg greyBg rounded-2 py-2 ps-3 shadow-sm" style={{ marginBottom: '45px' }}>
          <h4 className="mb-3 shooraBlue mt-2">Shoora EB5 Investors Live Event - The HSR Club - Bengaluru</h4>
          <p><strong>Date:</strong> <strong>05th August 2024, Monday</strong></p>
          <p><strong>Venue:</strong> The HSR Club</p>
          <p><strong>Timing:</strong> 11:00 AM to 3:00 PM (Local Time)</p>
          <p><strong>Location:</strong></p>
          <p>23rd Main, 3rd Sector CA-12, 22nd Cross Rd,</p>
          <p>near Narayana Hospital, HSR Layout,</p>
          <p>Bengaluru, Karnataka 560102, India</p>
          <div className="d-flex justify-content-between me-5">
            <p><a href="https://maps.app.goo.gl/29qmAshgDE1NjWvh6" target="_blank" rel="noopener noreferrer">View Map</a></p>
            <p><a className='shooraBluehover' onClick={() => handleShow('Shoora EB5 Investors Live Event - The HSR Club - Bengaluru')} target="_blank" rel="noopener noreferrer">Click here to register for the event.</a></p>
          </div>
        </div>
      </div> */}

      {/* <div className="row mx-md-3">
        <div className="col-lg-6 greyBg rounded-2 py-2 ps-3 shadow-sm">
        <h4 className="mb-3 shooraBlue mt-2">Shoora EB5 Investors Live Event - La Casa Brewery + Kitchen - Bengaluru</h4>
          <p><strong>Date:</strong> <strong>04th August 2024, Sunday</strong></p>
          <p><strong>Venue:</strong> La Casa Brewery + Kitchen</p>
          <p><strong>Timing:</strong> 6:00 PM to 9:00 PM (Local Time)</p>
          <p><strong>Location:</strong></p>
          <p>Amrutha Engineering College,</p>
          <p>NO 877/1/2 & 895/19/20, 1st Cross Rd,</p>
          <p>near ICICI BANK, opp. KFC, IAS Layout,</p>
          <p>Eastwood Twp, Kasavanahalli,</p>
          <p>Bengaluru, Karnataka 560035, India</p>

          <div className="d-flex justify-content-between me-5">
            <p><a href="https://g.co/kgs/TZsrYGs" target="_blank" rel="noopener noreferrer">View Map</a></p>
            <p><a className='shooraBluehover' onClick={() => handleShow('Shoora EB5 Investors Live Event - La Casa Brewery + Kitchen - Bengaluru')} target="_blank" rel="noopener noreferrer">Click here to register for the event.</a></p>
          </div>
        </div>
      </div> */}

      
<div className="d-flex justify-content-center mt-5 mb-4 ">

<img
            className="w-75"
            src={require("../Images/eventDetails.jpg")}
            alt="event"
            
          />
</div>

<div className={`modal ${showModal ? 'd-flex justify-content-center align-items-center' : 'd-none'}`} tabIndex="-1" role="dialog">
  <div className="modal-dialog mt-0 w-100 shadow-lg rounded-3 mx-3" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title shooraBlue">Register for the Event</h5>
        {error && (
          <div className="error-message">
            <h5 className="ValidationColor">{error}</h5>
          </div>
        )}
      </div>
      <div className="modal-body">
        <h6 className="modal-title mb-3">{selectedEventTitle}</h6>
        <h6 className="modal-title mb-3">{selectedVenueDetails}</h6>
        
        {/* Step 1: Venue Selection */}
        {!showForm && !eventIndexCodes[selectedEventTitle] && (
    <>
      <h5>Select a Venue</h5>
      {!showForm && events[selectedEventTitle]?.venues && events[selectedEventTitle].venues.length > 0 ? (
    events[selectedEventTitle].venues.map((venue, index) => (
      <div key={index} className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="venue"
          value={venue.locationId}
          onChange={() => handleVenueSelect(venue)}
          id={`venue${index}`}
          checked={selectedVenue === venue.locationId} // Automatically mark as checked
        />
        <label className="form-check-label" htmlFor={`venue${index}`}>
           {venue.cityName} - {venue.eventDate} @ {convertZuluToEST(venue.startTime)} - {venue.venue}
        </label>
      </div>
    ))
  ) : (
    !showForm && <div>No venues available</div> // Show only if the form is not displayed
  )}

    </>
  )}



{!showForm && !eventIndexCodes[selectedEventTitle] && (
    <div className="mt-3">
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleNext}
        disabled={!selectedVenue}
      >
        Next
      </button>
    </div>
  )}




        {/* Step 3: Show the form only if 'Next' is clicked */}
        {showForm && (
        <form className="mt-4">
          <div className="form-group mb-2">
            <label className="required" htmlFor="firstName">First Name</label>
            <input
              onChange={handleInput}
              value={data.firstName}
              type="text"
              className="form-control"
              name="firstName"
              placeholder="Enter first name"
            />
            {inputerr.firstName && <h6 className="ValidationColor">{inputerr.firstName}</h6>}
          </div>

          <div className="form-group mb-2">
            <label className="required" htmlFor="lastName">Last Name</label>
            <input
              onChange={handleInput}
              value={data.lastName}
              type="text"
              className="form-control"
              name="lastName"
              placeholder="Enter last name"
            />
            {inputerr.lastName && <h6 className="ValidationColor">{inputerr.lastName}</h6>}
          </div>

          <div className="form-group mb-2">
            <label className="required" htmlFor="email">Email</label>
            <input
              onChange={handleInput}
              value={data.email}
              type="email"
              className="form-control"
              name="email"
              placeholder="Enter email"
            />
            {inputerr.email && <h6 className="ValidationColor">{inputerr.email}</h6>}
          </div>


          {otpSent && (
            // <div className="form-group mb-2">
            //   <label className="required" htmlFor="otp">Enter OTP</label>
            //   <input
            //     type="text"
            //     className="form-control"
            //     name="otp"
            //     placeholder="Enter OTP"
            //     value={otp}
            //     onChange={(e) => setOtp(e.target.value)}
            //   />
            //   <button type="button" className="btn btn-primary" onClick={handleVerifyOTP}>Verify OTP</button>
            // </div>
            <>
            <OTPInput className="testInput my-3" value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} />

            <button type="button" className="btn btn-primary mt-1" onClick={handleVerifyOTP}>Verify OTP</button>
            </>
          )}

{!otpSent && !otpVerified && (
  <div className="d-flex">
    <button
      type="button"
      className="btn btn-primary mt-1"
      onClick={handleSendOTP}
      disabled={!isButtonEnabled}
    >
      Send OTP
    </button>
    {loading && (
      <Spinner
        animation="border"
        className="text-center forgetpass-spinner m-2"
        variant="warning"
      />
    )}
  </div>
)}

{otpSent && timer > 0 && !otpVerified && (
  <p className="validationColor mt-2">Resend OTP in: {formatTime(timer)}</p>
)}

{otpSent && timer === 0 && !otpVerified && (
  <button
    type="button"
    className="btn btn-secondary mt-1"
    onClick={handleSendOTP}
    disabled={!isButtonEnabled}
  >
    Resend OTP
  </button>
)}

{error && (
          <div className="error-message">
            <h6 className="ValidationColor ms-0 ps-0">{error}</h6>
          </div>
        )}

          <h5 className='mt-3 green'>{verifyMsg}</h5>



          <div className="form-group mb-2">
            <label className="required" htmlFor="contactNumber">Contact Number</label>
            <input
              onChange={handleInput}
              value={data.phoneNumber}
              type="text"
              className="form-control"
              name="phoneNumber"
              placeholder="Enter contact number"
            />
            {inputerr.phoneNumber && <h6 className="ValidationColor">{inputerr.phoneNumber}</h6>}
          </div>

          <div className="form-group mb-2">
            <label className="required mb-1" htmlFor="comment">
              <strong>How soon do you want to start the Green Card process?</strong>
            </label>
            <input
              onChange={handleInput}
              value={data.comment}
              type="text"
              className="form-control"
              name="comment"
              placeholder=""
            />
            {inputerr.comment && <h6 className="ValidationColor">{inputerr.comment}</h6>}
          </div>
        </form>
      )}
      </div>

      {/* Modal footer */}
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
        {showForm && (
  <button 
    type="button" 
    className="btn btn-primary" 
    onClick={handleonClick} 
    disabled={!otpVerified}  // Disable button if otpVerified is false
  >
    Submit
  </button>
)}
      </div>
    </div>
  </div>
</div>










    </div>





      <footer style={{ backgroundColor: '#2e2c7d', color: '#fefefe', padding: '20px' }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-3 mb-3 mb-md-0 d-flex align-items-center">
              <img
                className="me-3"
                src={require("../Images/shooraLogo.jpg")}
                alt="logo"
                style={{ height: "50px" }}
              />
            </div>
            <div className="col-12 col-md-9 text-center">
              <p><a href="https://www.shooraeb5.com" target="_blank" rel="noopener noreferrer" style={{ color: '#fefefe', textDecoration: 'none' }}>For more information visit: www.shooraeb5.com</a></p>
              <p><a href="https://www.shooraeb5.com/schedule-consultation" target="_blank" rel="noopener noreferrer" style={{ color: '#fefefe', textDecoration: 'none' }}>Schedule free consultation</a></p>
              <p><a href="https://www.shooraeb5.com/contact/" target="_blank" rel="noopener noreferrer" style={{ color: '#fefefe', textDecoration: 'none' }}>Contact us</a></p>
            </div>
          </div>
        </div>
      </footer>
   

    </>
  );
};

export default Main;
